// methods related to the manage group page

const Group = {};

// Current invite code to display
Group.inviteCode = null;

// The group name text in the UI
Group.currentGroupNameInUI = "";

// Check if dev mode is on or not
Group.isDevModeOn = function() {
	try {
		return new URLSearchParams(window.location.search).get('devmode') === 'true';
	}
	catch (error) {
		console.error("Failure when trying to get devmode from URL!", error);
		showErrorMessage("An unexpected failure occureed when trying to get development mode!");
	}
	return false;
};

Group.showGroupID = function()
{
	let encrkey = retrieveEncryptionKey();
	try {
		let base64 = CryptoUtil.bytesToBase64(encrkey);
		let textEl = document.getElementById('groupIdDivBody');
		let groupIdDiv = document.getElementById('groupIdDiv');
		textEl.textContent = 'Group ID: ' + base64;
		makeElementVisible(groupIdDiv);
	}
	catch (error) {
		console.error("Failure getting group id!", error);
		showErrorMessage("An unexpected failure occurred when trying to get the group ID!");
	}
};

Group.hideGroupID = function()
{
	let textEl = document.getElementById('groupIdDivBody');
	let groupIdDiv = document.getElementById('groupIdDiv');
	textEl.innerHTML = '';
	makeElementHidden(groupIdDiv);
};

// Write notification message
// Overwrites text that is already there
// text : the text to show
// progressing : if true a progressing icon will be shown
Group.writeNotification = function(text, progressing)
{
	let mainNotificationDivInner = document.getElementById('mainNotificationDivInner');
	if (progressing)
	{
		mainNotificationDivInner.classList.remove('notification');
		mainNotificationDivInner.classList.add('notification--progress');
	}
	else
	{
		mainNotificationDivInner.classList.remove('notification--progress');
		mainNotificationDivInner.classList.add('notification');
	}
	let textEl = document.getElementById('mainNotificationBody');
	let mainNotificationDiv = document.getElementById('mainNotificationDiv');
	textEl.textContent = text;
	makeElementVisible(mainNotificationDiv);
};

Group.updateGroupNameInUI = async function(linked)
{
	try {
		let groupName = '';
		if (linked)
		{
			// Get group name
			var encrkey = retrieveEncryptionKey();
			var indexRes = await Cloud.getIndexFromCloud(encrkey);

			if(!indexRes.success)
			{
				let indexObjName = Cloud.getIndexObjectName(encrkey);
				showErrorMessage("Failed to download group information from cloud ("
				+ indexObjName + ").<br />HTTP status code : " + indexRes.httpStatusCode);
			}
			else
			{
				if (indexRes.indexFile.GroupName != null)
				{
					groupName = indexRes.indexFile.GroupName;
				}
			}
			let groupNameInputUpdate = document.getElementById("groupNameInputUpdate");
			groupNameInputUpdate.value = groupName;
			Group.currentGroupNameInUI = groupName;
		}
		else
		{
			var groupNameInput = document.getElementById("groupNameInput");
			groupName = Util.getCurrentFormattedUTCDateForUI();
			groupNameInput.value = groupName;
			Group.currentGroupNameInUI = groupName;
		}
	}
	catch (error) {
		console.error('Failure to update group name in UI', error);
		showErrorMessage("An unexpected error occurred while trying to update group name in the UI!");
	}
};

Group.showInviteCode = function(inviteCode)
{
	let span = document.getElementById('InviteCodeSpan');
	span.textContent = inviteCode;
};

// Update UI of manage group page
Group.updateUI = function()
{
	let copyMessageSpan = document.getElementById("CopyMessageSpan");
	makeElementHidden(copyMessageSpan);

	// Check if the group id can be retrieved from the URL
	let groupIdFromUrl = getEncryptionKeyFromUrl() === null ? false : true;

	// Check if we are linked to a group
	let linked = isLinked();

	let unlinkButton = document.getElementById("unlinkButton");
	let createNewGroupSection = document.getElementById("CreateNewGroupSection");
	let joinGroupSection = document.getElementById("JoinGroupSection");
	let linkedGroupSection = document.getElementById("LinkedGroupSection");
	let inviteCodeSection = document.getElementById("InviteCodeSection");
	let uploadsLink = document.getElementById("uploadsLink");
	let downloadsLink = document.getElementById("downloadsLink");
	let accLink = document.getElementById("accLink");
	let uploadIconEnabled = getel("uploadIconEnabled");
	let uploadIconDisabled = getel("uploadIconDisabled");
	let downloadIconEnabled = getel("downloadIconEnabled");
	let downloadIconDisabled = getel("downloadIconDisabled");
	let autodeskIconEnabled = getel("autodeskIconEnabled");
	let autodeskIconDisabled = getel("autodeskIconDisabled");
	//var dataStreamingLinkItem = getel("dataStreamingLinkItem");

	if (groupIdFromUrl) {
		makeElementHidden(unlinkButton);
	} else {
		makeElementVisible(unlinkButton);
	}

	if (linked)
	{
		let devmode = Group.isDevModeOn();
		if (devmode)
		{
			Group.showGroupID();
		}
		else
		{
			Group.hideGroupID();
		}

		makeElementHidden(createNewGroupSection);
		makeElementHidden(joinGroupSection);
		makeElementVisible(linkedGroupSection);
		uploadsLink.classList.remove('disabled');
		downloadsLink.classList.remove('disabled');
		accLink.classList.remove('disabled');
		makeElementHidden(uploadIconDisabled);
		makeElementHidden(downloadIconDisabled);
		makeElementHidden(autodeskIconDisabled);
		makeElementVisible(uploadIconEnabled);
		makeElementVisible(downloadIconEnabled);
		makeElementVisible(autodeskIconEnabled);

		//dataStreamingLinkItem.classList.remove('disabled');

		if (Group.inviteCode != null)
		{
			let inviteCodeSpan = document.getElementById('InviteCodeSpan');
			inviteCodeSpan.textContent = Group.inviteCode;
			makeElementVisible(inviteCodeSection);
		}
		else
		{
			makeElementHidden(inviteCodeSection);
		}
	}
	else
	{
		makeElementHidden(linkedGroupSection);
		makeElementHidden(inviteCodeSection);
		uploadsLink.classList.add('disabled');
		downloadsLink.classList.add('disabled');
		accLink.classList.add('disabled');
		makeElementHidden(uploadIconEnabled);
		makeElementHidden(downloadIconEnabled);
		makeElementHidden(autodeskIconEnabled);
		makeElementVisible(uploadIconDisabled);
		makeElementVisible(downloadIconDisabled);
		makeElementVisible(autodeskIconDisabled);
		//dataStreamingLinkItem.classList.add('disabled');
		makeElementVisible(createNewGroupSection);
		makeElementVisible(joinGroupSection);
		Group.hideGroupID();
	}

	Group.updateGroupNameInUI(linked);
};

Group.unlinkFromCloud = async function()
{
	encryptionKey = null;
	localStorage.setItem(Constants.EncryptionKeyItemName, null);
	await logOutOfACC();
	Group.writeNotification("Left Group!", false);
	Group.updateUI();
};

Group.createNewGroup = async () =>
{
	var result = await Cloud.createGroup();
	if(result.success)
	{
		Group.inviteCode = result.inviteCode;
		var encrkey = result.encryptionKey;
		storeEncryptionKey(encrkey);
		await Group.setGroupName(false);
		Group.writeNotification('Created new group. Invite code: ' + result.inviteCode, false);
	}
	else
	{
		showErrorMessage("Group creation failed!");
	}
	
	Group.updateUI();
};

// Create an invite code for an already existing connection
Group.createInviteCode = async () =>
{
	if (isLinked())
	{
		var encrkey = retrieveEncryptionKey();
		var result = await Cloud.getNewInviteCode(encrkey);
		if (result.success)
		{
			Group.writeNotification('New invite code: ' + result.inviteCode, false);
			Group.inviteCode = result.inviteCode;
		}
		else
		{
			showErrorMessage("Failed to get invite code!");
		}
		Group.updateUI();
	}
	else
	{
		// If user is not linked then leave group
		Group.unlinkFromCloud();
	}
};

Group.linkWithCloud = async () =>
{
	var keycodeInputEl = document.getElementById("keycodeInput");
	var code = keycodeInputEl.value;
	code = code.trim();

	if (Group.isDevModeOn())
	{
		if (code.length === 43 || code.length === 44)
		{
			let ek = null;
			try {
				ek = CryptoUtil.base64ToBytes(code);
			}
			catch (error) {
				let msg = "Unexpected error while processing key!";
				console.error(msg + ": ", error);
				showErrorMessage(msg);
				return;
			}
			console.log('Storing encryption key from input field: ' + code);
			storeEncryptionKey(ek);
			Group.writeNotification("Joined group using group ID!", false);
			Group.updateUI();
			return;
		}
	}

	code = code.toUpperCase();
	keycodeInputEl.value = code;

	if (code.length !== 6)
	{
		showErrorMessage('Length of invite code should be 6 letters!');
		return;
	}

	var result = await Cloud.getLongKey(code);

	if (result.success)
	{
		Group.writeNotification("Joined Group!", false);

		var encrkey = result.encryptionKey;
		storeEncryptionKey(encrkey);
	}
	else
	{
		if (result.httpStatusCode === 404)
		{
			showErrorMessage("Code does not exist!");
		}
		else
		{
			showErrorMessage("Joining group failed due to technical reasons!");
		}
	}
	Group.updateUI();
};

// A way to copy text to clipboard that works in older browsers
// Returns true if successfull
Group.fallbackCopyToClipboard = function (text) {
    var textarea = document.createElement('textarea');
    textarea.value = text;
    textarea.style.position = 'fixed';  // Prevent scrolling to bottom of page in MS Edge.
    textarea.style.opacity = '0';  // Ensure the textarea is not visible.
    
    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();

	let success = false;
    try {
        success = document.execCommand('copy');
        console.log('fallbackCopyToClipboard: Copying text command was ' + (success ? 'successful' : 'unsuccessful'));
		return success;
    } catch (err) {
        console.error('fallbackCopyToClipboard: Unable to copy text', err);
    }

    document.body.removeChild(textarea);

	return success;
};

// Copies text to clipboard
Group.copyToClipboard = async function (text) {
    if (navigator.clipboard) {
        try {
            await navigator.clipboard.writeText(text);
            console.log('Text copied to clipboard successfully!');
            return true;
        } catch (error) {
            console.error('Failed to copy text: ', error);
            return false;
        }
    } else {
        // Fallback method
        return Group.fallbackCopyToClipboard(text);
    }
};

Group.copyURL = async function ()
{
	let encrkey = retrieveEncryptionKey();
	if (encrkey === null) {
		let msg = "Could not retrieve the encryption key!";
		console.error(msg);
		showErrorMessage(msg);
		return;
	}
	try {
		let base64 = CryptoUtil.bytesToBase64(encrkey);

		//TODO need to make sure this works everywhere
		let url = window.location.protocol + "//" + window.location.host + "?groupid=" + encodeURIComponent(base64);
		
		// Copy the url to the clipboard
		let success = await Group.copyToClipboard(url);
		if (success) {
			// Show a message to the user about the url being copied
			let copyMessageSpan = document.getElementById("CopyMessageSpan");
			makeElementVisible(copyMessageSpan);
		} else {
			showErrorMessage("Failed to copy text to the clipboard!");
		}
	}
	catch (error) {
		console.error("Failure getting group URL!", error);
		showErrorMessage("An unexpected failure occurred when trying to get the group URL!");
	}
};

// Gets group name from UI and sets it in the cloud
// Updates the UI after setting the group name
Group.setGroupName = async (update) => {

	if (!isLinked())
	{
		// If not linked then make sure the page shows that
		Group.unlinkFromCloud();
		return;
	}

	// disable group name input box and button while setting the group name
	let groupNameInputUpdate = document.getElementById('groupNameInputUpdate');
	groupNameInputUpdate.disabled = true;

	Group.writeNotification("Setting group name.", true);
	console.log('Getting encryption key.');
	var encrkey = retrieveEncryptionKey();

	let groupName = "";
	if (update)
	{
		let groupNameInputUpdate = document.getElementById('groupNameInputUpdate');
		groupName = groupNameInputUpdate.value;
	}
	else
	{
		let groupNameInput = document.getElementById('groupNameInput');
		groupName = groupNameInput.value;
	}

	console.log("Attempting to set group name to: " + groupName);

	let res = await Cloud.setGroupName(groupName, encrkey);
	if (res.success)
	{
		Group.writeNotification("Group name set to " + groupName + '.', false);
	}
	else
	{
		showErrorMessage("Failed to set group name!");
	}

	groupNameInputUpdate.disabled = false;

	Group.updateUI();
};

// Reads the value in the group name update input box
// and if it has changed then update the group name
// in the cloud
Group.updateGroupNameIfNeeded = async function()
{
	let groupNameTextbox = document.getElementById("groupNameInputUpdate");
	let newGroupName = groupNameTextbox.value;
	if (Group.currentGroupNameInUI !== newGroupName) await Group.setGroupName(true);
};

Group.groupNameKeyDown = async function(event) {
	if (event.key === "Enter") {
		await Group.updateGroupNameIfNeeded();
	}
};

Group.groupNameBlur = async function(event) {
	await Group.updateGroupNameIfNeeded();
};

Group.bodyOnLoad = function()
{
	addGroupIdToTabLinksIfNeeded();

	// For showing an error message if user clicks on one
	// of those disabled tabs when not connected to a group.
	['uploadLinkItem', 'downloadLinkItem', 'accLinkItem'].forEach(function(item, index) {
		getel(item).addEventListener('click', function(event) {
			if (!isLinked()) showErrorMessage(
				"Create or join a cloud group to access uploads, Arkio exports, or link your ACC account.");
		});
	});

	let groupNameTextbox = document.getElementById("groupNameInputUpdate");
	// Add event listener for keydown event
	groupNameTextbox.addEventListener("keydown", Group.groupNameKeyDown);
	// Add event listener for blur event
	groupNameTextbox.addEventListener("blur", Group.groupNameBlur);

	Group.updateUI();
};
